/* 
This is just a temp config as we have to retrieve supernova generated UUID's from temp json files.
When endpoint is ready, we will contact supernova directly to retieve all the content data.
Will see if they can rename the buckets to dev/prod instead of generic numbers 
*/

const clientSide = typeof window !== "undefined";

const isDev =
  process.env.ASTRONOMER_ORIGIN &&
  process.env.ASTRONOMER_ORIGIN.includes("dev");

const supernovaExternalEnvPrefix = isDev ? "851725219336" : "058264216542";

const SUPERNOVA_S3_URL = `https://${supernovaExternalEnvPrefix}-supernova-articles.s3.amazonaws.com/`;

const SUPERNOVA_S3 = clientSide
  ? "/_yc/api/astronomer-stream/"
  : SUPERNOVA_S3_URL;

// Export for CommonJS as to work in rewrites() next.config.js
module.exports = {
  SUPERNOVA_S3,
  SUPERNOVA_S3_URL,
};
