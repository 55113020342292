import { type StreamItemSource, type Reason } from "../../shared/Stream/data";
import {
  type CategoryBadge,
  categoryBadgeType,
  getCategoryBadge,
} from "./category";
import {
  type ContextualBadge,
  contextualBadgeType,
  getContextualBadges,
} from "./contextual";
import {
  type EngagementBadge,
  engagementBadgeType,
  getEngagementBadge,
} from "./engagement";
import { getlLiveBadges, type LiveBadge, liveBadgeType } from "./live";

// In priority order, don't change this unless the badging order changes
export const badgeTypes = [
  "Live",
  "Contextual",
  "Engagement",
  "Category",
] as const;
export type BadgeType = (typeof badgeTypes)[number];

export type Badge = {
  id: LiveBadge | ContextualBadge | EngagementBadge | CategoryBadge;
  type: BadgeType;
  value?: number | string;
  provider?: StreamItemSource;
  showCreatorLogo?: boolean;
  badgeColor?: string;
};

export const SITE_BADGE_ALLOWLIST: { [key: string]: BadgeType[] } = {
  creators: ["Live", "Contextual", "Engagement", "Category"],
};

export const NTK_BADGE_ALLOWLIST: BadgeType[] = [
  "Live",
  "Contextual",
  "Engagement",
];

export const NTK_NEBULA_ALLOWLIST: BadgeType[] = [
  "Live",
  "Contextual",
  "Engagement",
  "Category",
];

export const SECTION_BADGE_ALLOWLIST: BadgeType[] = [
  "Live",
  "Contextual",
  "Engagement",
];

export const STREAM_BADGE_ALLOWLIST: BadgeType[] = [
  "Live",
  "Contextual",
  "Engagement",
  "Category",
];

export type GetBadgesProps = {
  categoryLabel?: string;
  isCreatorContent?: boolean;
  isOpinion?: boolean;
  liveBlogStatus?: "live" | null;
  provider?: StreamItemSource;
  reasons?: Reason[] | null;
};

const getBadgeByType = (
  badgeType: BadgeType,
  {
    categoryLabel,
    isCreatorContent,
    isOpinion,
    liveBlogStatus,
    provider,
    reasons,
  }: GetBadgesProps,
): Badge[] | undefined => {
  const isLiveBlog = liveBlogStatus === "live";

  switch (badgeType) {
    case liveBadgeType:
      return getlLiveBadges({
        isLiveBlog,
      });
    case contextualBadgeType:
      return getContextualBadges({
        isCreatorContent,
        isOpinion,
        provider,
      });
    case engagementBadgeType:
      return getEngagementBadge({ reasons });
    case categoryBadgeType:
      if (categoryLabel) {
        return getCategoryBadge({ categoryLabel });
      }
  }
};

export const getBadges = ({
  categoryLabel,
  isCreatorContent,
  isOpinion,
  liveBlogStatus,
  provider,
  reasons,
}: GetBadgesProps): Badge[] => {
  let badges: Badge[] = [];
  for (let badgeType of badgeTypes) {
    const badgesForType = getBadgeByType(badgeType, {
      categoryLabel,
      isCreatorContent,
      isOpinion,
      liveBlogStatus,
      provider,
      reasons,
    });

    if (badgesForType?.length) {
      badges = badges.concat(badgesForType);
    }
  }

  return badges;
};

export const getBadgeI13n = (badges?: Badge[]) => {
  return badges?.map(({ id }) => `badge-${id.toLowerCase()}`).join("|");
};
