import { type Reason } from "../../shared/Stream/data";
import { type Badge } from "./badge";

export const engagementBadges = [
  "MOST_COMMENTED",
  "MOST_SHARED",
  "POPULAR",
] as const;

export const engagementBadgeType = "Engagement" as const;

export type EngagementBadge = (typeof engagementBadges)[number];

export const getEngagementBadge = ({
  reasons,
}: {
  reasons?: Reason[] | null;
}): Badge[] | undefined => {
  const reasonNames = reasons?.map((reason: Reason) =>
    reason.match.toUpperCase(),
  );
  for (const badge of engagementBadges) {
    if (reasonNames?.includes(badge)) {
      return [{ id: badge, type: engagementBadgeType }];
    }
  }
};
