export class HttpError extends Error {
  statusCode: number;
  statusText: string;

  constructor({
    statusCode,
    statusText,
    message = `${statusCode} ${statusText}`,
  }: {
    message?: string;
    statusCode: number;
    statusText: string;
  }) {
    super(message);

    this.statusCode = statusCode;
    this.statusText = statusText;
  }
}

export class TimeoutError extends Error {
  constructor(...args: ConstructorParameters<typeof Error>) {
    super(...args);
    this.cause = "timeout";
  }
}

export class FetchError extends HttpError {
  response: Response;

  constructor(response: Response) {
    super({ statusCode: response.status, statusText: response.statusText });

    this.response = response;
  }
}

export class InternalServerError extends HttpError {
  constructor(message: string) {
    super({ message, statusCode: 500, statusText: "Internal Server Error" });
  }
}

export class NotFoundError extends HttpError {
  constructor(message?: string) {
    super({ message, statusCode: 404, statusText: "Not Found" });
  }
}

class RedirectError extends HttpError {
  location: string;

  constructor({
    location,
    statusCode,
    statusText,
  }: {
    location: string;
    statusCode: number;
    statusText: string;
  }) {
    super({ statusCode, statusText });

    this.location = location;
  }
}

const permanentRedirectStatusCodes = new Set<number>([301, 308]);
export const isPermanentRedirectStatusCode = (statusCode: number) =>
  permanentRedirectStatusCodes.has(statusCode);

export class PermanentRedirectError extends RedirectError {
  constructor({ location }: { location: string }) {
    super({ location, statusCode: 308, statusText: "Permanent Redirect" });
  }
}

const temporaryRedirectStatusCodes = new Set<number>([302, 303, 307]);
export const isTemporaryRedirectStatusCode = (statusCode: number) =>
  temporaryRedirectStatusCodes.has(statusCode);

export class TemporaryRedirectError extends RedirectError {
  constructor({ location }: { location: string }) {
    super({ location, statusCode: 307, statusText: "Temporary Redirect" });
  }
}
