import benjiManifest from "@vzmi/benji/manifest.json";
import Script from "next/script";
import { type FC } from "react";
import {
  Ad,
  type AdWrapperProps,
  type BaseAdProps,
  type ScreenSpecificAdProps,
} from "./Ad";
import { type Device } from "./benji";
import { type ScreenWidth, type Size } from "./client";

const BENJI_URL = benjiManifest.benji.default.min;

interface ScreenSpecificAdInput {
  customSizeConfig?: Record<string, boolean>;
  device: Device;
  ntsFallBack?: {
    position: string;
  };
  placeholder?: Size;
  screenWidth: Partial<ScreenWidth>;
  sizes: Size[];
  wrapper?: FC<AdWrapperProps>;
}

export const buildResponsiveAdProps = (
  inputs: ScreenSpecificAdInput[],
): ScreenSpecificAdProps[] => {
  const configs: ScreenSpecificAdProps[] = inputs
    .map(
      (
        {
          customSizeConfig,
          device,
          ntsFallBack,
          placeholder,
          screenWidth,
          sizes,
          wrapper,
        },
        index,
      ) => {
        const config = {
          customSizeConfig,
          device,
          ntsFallBack,
          placeholder: placeholder ?? sizes[0],
          screenWidth: {
            from: screenWidth.from ?? 0,
            to: screenWidth.to ?? Infinity,
          },
          sizes,
          wrapper,
        };

        if (config.screenWidth.from >= config.screenWidth.to) {
          throw new TypeError(`inputs[${index}] has an invalid screenWidth`);
        }

        return config;
      },
    )
    .sort((a, b) => a.screenWidth.from - b.screenWidth.from); // ascending order by min screenWidth

  // check for overlapping screenWidths
  if (configs.length > 1) {
    let previousConfig = configs[0];
    for (const config of configs.slice(1)) {
      if (config.screenWidth.from < previousConfig.screenWidth.to) {
        throw new TypeError(`inputs contain overlapping screenWidths`);
      }
    }
  }

  return configs;
};

interface ResponsiveAdProps extends BaseAdProps {
  configs: ScreenSpecificAdProps[];
  kvs?: Record<string, string>;
}

/**
 * Renders an ad with Benji, supporting different `sizes` based on the device's
 * size (inferred from the viewport's largest visible breakpoint)
 *
 * NOTE: this component accepts object and function props, which can cause extra
 * rerenders, and rerenders of the Ad component noticeably slows down the ad's
 * display. To prevent this, it's recommended that you define each ad slot as
 * a new, memoized client component that just accepts primitive props
 */
export const ResponsiveAd: FC<ResponsiveAdProps> = ({
  className = "flex",
  configs,
  kvs,
  loading,
  location,
  locationNumber,
  onResize,
  pageRegion,
  placement,
  platform,
  resizable,
  site,
  stackGroup,
  id,
}) => (
  <>
    {configs.map((config) => (
      <Ad
        className={className}
        key={config.screenWidth.from}
        kvs={kvs}
        loading={loading}
        location={location}
        locationNumber={locationNumber}
        onResize={onResize}
        pageRegion={pageRegion}
        placement={placement}
        platform={platform}
        resizable={resizable}
        site={site}
        stackGroup={stackGroup}
        id={id ? `${id}_${config.screenWidth.from}` : undefined}
        {...config}
      />
    ))}
    <Script src={BENJI_URL} />
  </>
);
