import { type StreamImage } from "@/components/shared/Stream/data";
import { type CaasImageProps } from "@/types/Caas";

export const getImageSize = ({
  image,
  tag,
}: {
  image?: StreamImage | null;
  tag: string;
}): CaasImageProps | null => {
  const resolution = image?.resolutions?.find(
    (resolution) => resolution?.tag === tag,
  );
  if (!resolution || !resolution.height || !resolution.width) {
    return null;
  }

  return {
    height: resolution.height,
    url: resolution.url,
    width: resolution.width,
  };
};
