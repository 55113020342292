import * as Perf from "@yahoo-creators/perf";

export const fetchWithPerf: typeof fetch = async (...args) => {
  const perf = Perf.getInstance();
  const entry = perf?.addEntry();
  const res = await fetch(...args);

  if (entry && perf?.enabled) {
    entry.resolve(new Request(...args), res.clone());
  }

  return res;
};
