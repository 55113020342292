import { type StreamItemSource, type Reason } from "../../shared/Stream/data";
import { type Badge } from "./badge";

export const contexualBadges = ["OPINION", "CREATOR"] as const;

export const contextualBadgeType = "Contextual" as const;

export type ContextualBadge = (typeof contexualBadges)[number];

export const getOpinionBadge = ({
  isOpinion,
}: {
  isOpinion?: boolean;
}): Badge[] | undefined => {
  if (!isOpinion) return;
  return [{ id: "OPINION", type: contextualBadgeType }];
};

export const getCreatorBadge = ({
  isCreatorContent,
  provider,
}: {
  isCreatorContent?: boolean;
  provider?: StreamItemSource;
}): Badge[] | undefined => {
  if (!isCreatorContent) return;
  return [{ id: "CREATOR", provider, type: contextualBadgeType }];
};

export const getContextualBadges = ({
  isCreatorContent,
  isOpinion,
  provider,
}: {
  isCreatorContent?: boolean;
  isOpinion?: boolean;
  reasons?: Reason[] | null;
  provider?: StreamItemSource;
}): Badge[] | undefined => {
  const opinionBadge = getOpinionBadge({ isOpinion });
  if (opinionBadge) return opinionBadge;

  const creatorBadge = getCreatorBadge({ isCreatorContent, provider });
  if (creatorBadge) return creatorBadge;
};
