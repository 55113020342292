import { type NCPStreamViewParams } from "@/lib/ncp";
import { type SubnavigationListContentItem } from "@/types/TopicsSubnavigation";
import {
  type CommerceModuleAdditionalCta,
  type Crop,
} from "../../../../generated-types/stream-types";

export const INTEREST_CONFIGS: Record<
  string,
  { configDocId?: string; url: string }
> = {
  business: {
    url: "https://www.yahoo.com/news/business/",
  },
  celebrity: {
    url: "https://www.yahoo.com/entertainment/celebrity/",
  },
  entertainment: {
    url: "https://www.yahoo.com/entertainment/",
  },
  lifestyle: {
    url: "https://www.yahoo.com/lifestyle/",
  },
  politics: {
    configDocId: "a6356237-b474-35c3-8eca-8a398313d67a",
    url: "https://www.yahoo.com/news/politics/",
  },
  science: {
    url: "https://www.yahoo.com/news/science/",
  },
  sports: {
    url: "https://sports.yahoo.com/",
  },
  style: {
    url: "https://www.yahoo.com/lifestyle/style/",
  },
  technology: {
    url: "https://www.yahoo.com/tech/",
  },
  us: {
    url: "https://www.yahoo.com/news/us/",
  },
  world: {
    url: "https://www.yahoo.com/news/world/",
  },
};

type Maybe<T> = T | null;

export interface HPStreamViewParams extends NCPStreamViewParams {
  adsClusterEnabled: boolean;
  adsContentType: string;
  adsDynamicSlottingEnabled: boolean;
  adsEnabled: boolean;
  adsFrequency: number;
  adsImageTags: string;
  adsPublisherUrl: string;
  adsSectionId: number;
  adsSlotsEnabled: boolean;
  adsStartIndex: number;
  adsTimeout: number;
  adsType: string;
  adsVideoMaxCount: number;
  bucketId: string;
  checkBettingEligibility: boolean;
  contentBadgesAppId: string;
  device: string;
  diagnostics: boolean;
  lang: string;
  listCount: number;
  listStartIndex: number;
  mabContentEnabled: boolean;
  minItemCount: number;
  mmAdsType: string;
  ntkEnabled?: boolean;
  pageContext: {
    appName: string;
    lu: 0;
    pageType: string;
    site: string;
  };
  pageIndex: number;
  providerLogoSizes: string;
  providerLogoImageTags: string;
  region: string;
  site: string;
  slideshowImageTags: string;
  smartCropEnabled: boolean;
  spaceId?: string;
  ssl: boolean;
  storyline: boolean;
  storylineCount: number;
  storylineImageTags: string;
  subsite: string;
  woeid?: string;
}

export interface StreamItem {
  id: string;
  content: StreamItemContent;
}

export type ContentBadges = {
  shareCount?: number;
  lifetimeShareCount?: number;
  viewCount?: number;
  lifetimeViewCount: number;
};

export interface CommerceModuleProductListItem {
  productId: string;
  manualEntry: boolean;
  affiliateService: string;
  affiliateUrl: string;
  editorialLabel: string;
  editorialText: string;
  editorialSubhead: string;
  merchantName: string;
  salePrice: string;
  originalPrice: string;
  overrides: {
    discountedPrice: string;
    merchant: string;
    regularPrice: string;
    productName: string;
  };
  overridesImage: {
    credit: string;
    id: string | null;
    originalUrl: string;
    originalHeight: number;
    originalWidth: number;
    resolutions: StreamImageResolution[] | null;
  };
  overridesCoupon?: {
    code: string;
    label: string;
  } | null;
  additionalButtonsGenerationType: string;
  gtins: [string];
  rating: string;
  pros: [string] | [];
  cons: [string] | [];
  additionalButtons: [CommerceModuleAdditionalCta] | [];
}

export interface DealOfTheDayItem {
  content: StreamItemContent & {
    commerceModuleProductList?: [
      {
        id: string;
        moduleStyle: string;
        commerceModuleProduct: CommerceModuleProductListItem[];
      },
    ];
  };
}

export interface StreamItemContent {
  authors?: Author[];
  canonicalUrl: {
    url: string;
  };
  canvass: {
    count: number;
  };
  clickThroughUrl: null | {
    url: string;
  };
  contentBadges?: ContentBadges;
  bypassModal: boolean;
  categories?: Category[];
  categoryLabel: string;
  commentCount?: Maybe<number>;
  commentsAllowed?: boolean;
  contentType: string;
  description?: string;
  duration?: number;
  entities?: Entity[];
  finance?: {
    stockTickers: unknown;
  };
  generatedData?: {
    summary: {
      texts: string[];
      status?: string;
      version?: string;
    } | null;
  };
  id: string;
  instrumentation?: Instrumentation;
  isCreatorContent?: boolean;
  isHosted: boolean;
  isOpinion?: boolean;
  isRead?: boolean;
  lastModifiedTime?: string;
  liveBlogStatus?: "live" | null;
  presentation: unknown;
  previewUrl?: unknown;
  provider: StreamItemSource;
  providerContentUrl?: string;
  pubDate: string;
  readingMeta?: ReadingMeta;
  reasons?: Reason[] | null;
  salientEntities?: SalientEntity[];
  slides?: unknown;
  sports?: {
    contentTeamIds: unknown;
  };
  storyline?: unknown;
  subType?: unknown;
  summary: string;
  tags?: string[];
  thumbnail?: null | StreamImage;
  title: string;
}

export interface Instrumentation {
  mab: null | {
    mabLogString: string;
  };
}

export interface ReadingMeta {
  className?: string;
  wordCount?: number;
  wpm200?: number;
}

export interface StreamImage {
  altText?: string;
  caption?: string;
  crop?: Crop;
  originalHeight: number;
  originalUrl: string;
  originalWidth: number;
  resolutions: StreamImageResolution[];
}

export interface StreamImageResolution {
  height: number;
  tag: string;
  url: string;
  width: number;
}

export interface StreamItemSource {
  displayName: string;
  imageByType?: (StreamImage & { altText: string }) | null;
  logo?: (StreamImage & { altText: string }) | null;
  providerBrand?: {
    brandId?: string;
    description?: string;
    brandUrl?: { url: string };
    displayName: string;
    id?: string;
    isCreator?: boolean;
    logoImage?: StreamImage | null;
    imageByType?: StreamImage | null;
    secondaryTypes?: string[];
  };
  sourceId?: string;
  url: string;
}

type Entity = {
  score: number;
  text: string;
  wikiId: string;
};

export type Author = {
  author: Maybe<{
    alias?: string;
    displayName: string;
    image?: StreamImage | null;
  }>;
  id: string;
};

type Category = {
  code: string;
  name: string;
  score: number;
};

export type Reason = {
  id: string;
  match: string;
};

type SalientEntity = {
  canonicalId: string;
  canonicalType: string;
  secondaryIds: {
    wiki_id: string;
    yk_id: string;
  };
  text: string;
  type: string;
};

export interface NCPStreamState<ItemType> {
  error?: any;
  items: ItemType[];
  loadingState: "error" | "finished" | "loading" | "ready";
}

export const isShoppingStreamItem = (item: unknown): item is StreamItem => {
  if (typeof item !== "object" || item === null) {
    return false;
  }
  const streamItem = item as StreamItem;
  return (
    typeof streamItem.content?.clickThroughUrl?.url === "string" &&
    typeof streamItem.content?.id === "string" &&
    typeof streamItem.content?.title === "string"
  );
};

export const isDealOfTheDayItem = (item: unknown): item is DealOfTheDayItem =>
  typeof (item as DealOfTheDayItem)?.content?.commerceModuleProductList?.[0]
    ?.commerceModuleProduct?.[0] === "object";

export const isEditorialListStreamItem = (item: unknown): item is StreamItem =>
  typeof item === "object" &&
  item !== null &&
  typeof (item as StreamItem)?.content?.canonicalUrl?.url === "string" &&
  typeof (item as StreamItem)?.content?.contentType === "string" &&
  typeof (item as StreamItem)?.content?.id === "string" &&
  typeof (item as StreamItem)?.content?.summary === "string" &&
  typeof (item as StreamItem)?.content?.title === "string";

export const isStreamItem = (item: unknown): item is StreamItem =>
  typeof item === "object" &&
  item !== null &&
  typeof (item as StreamItem)?.content?.clickThroughUrl?.url === "string" &&
  typeof (item as StreamItem)?.content?.contentType === "string" &&
  typeof (item as StreamItem)?.content?.id === "string" &&
  typeof (item as StreamItem)?.content?.summary === "string" &&
  typeof (item as StreamItem)?.content?.title === "string";

export const isLiveEventStreamItem = (item: unknown): item is StreamItem =>
  typeof item === "object" &&
  item !== null &&
  typeof (item as StreamItem)?.content?.clickThroughUrl?.url === "string" &&
  typeof (item as StreamItem)?.content?.contentType === "string" &&
  typeof (item as StreamItem)?.content?.id === "string" &&
  typeof (item as StreamItem)?.content?.title === "string";

export const isWebstoryItem = (item: unknown): item is StreamItem =>
  typeof item === "object" &&
  item !== null &&
  typeof (item as StreamItem)?.content?.clickThroughUrl?.url === "string" &&
  typeof (item as StreamItem)?.content?.contentType === "string" &&
  typeof (item as StreamItem)?.content?.id === "string" &&
  typeof (item as StreamItem)?.content?.title === "string" &&
  (typeof (item as StreamItem)?.content?.clickThroughUrl?.url === "string" ||
    typeof (item as StreamItem)?.content?.canonicalUrl?.url === "string" ||
    typeof (item as StreamItem)?.content?.providerContentUrl === "string") &&
  typeof (item as StreamItem)?.content?.thumbnail?.originalUrl === "string";

export const isSubNavigationItem = (
  item: unknown,
): item is SubnavigationListContentItem =>
  typeof item === "object" &&
  item !== null &&
  typeof (item as SubnavigationListContentItem)?.content?.clickThroughUrl
    ?.url === "string" &&
  typeof (item as SubnavigationListContentItem)?.content?.id === "string" &&
  typeof (item as SubnavigationListContentItem)?.content?.title === "string" &&
  typeof (item as SubnavigationListContentItem)?.content?.canonicalUrl?.url ===
    "string" &&
  typeof (item as SubnavigationListContentItem)?.content?.clickThroughUrl
    ?.url === "string" &&
  typeof (item as SubnavigationListContentItem)?.content?.bypassModal ===
    "boolean" &&
  typeof (item as SubnavigationListContentItem)?.content?.providerContentUrl ===
    "string" &&
  typeof (item as SubnavigationListContentItem)?.content?.isHosted ===
    "boolean";
