import {
  type StreamItemSource,
  type Author,
  type StreamImage,
} from "@/components/shared/Stream/data";

const ymediaTags = ["ymedia:category=100000000", "ymedia:category=100000009"];
export const hasYMediaTag = (tags?: string[]): boolean => {
  return !!tags && !!ymediaTags.find((tag) => tags.includes(tag));
};

const getAuthorImage = (authors?: Author[]) => {
  if (!authors) return;
  const firstAuthorWithImage = authors.find(({ author }) => author?.image);
  return firstAuthorWithImage?.author?.image;
};

const getAuthorNames = (authors?: Author[]) => {
  return authors?.flatMap(({ author }) => author?.displayName || []);
};

export type Attribution = {
  authors?: string[];
  image?: StreamImage | null;
  isCreatorContent?: boolean;
  source: string;
};

export const getAttribution = ({
  authors,
  provider,
  tags,
}: {
  authors?: Author[];
  isCreatorContent?: boolean;
  provider: StreamItemSource;
  tags?: string[];
}): Attribution => {
  const authorImage = getAuthorImage(authors);
  const brandImage =
    provider.providerBrand?.imageByType || provider.providerBrand?.logoImage;
  const sourceLogo = provider.logo;
  const isOriginalContent = hasYMediaTag(tags);
  const isCreatorContent =
    provider?.providerBrand?.secondaryTypes?.includes("creator");

  const authorNames = isOriginalContent ? getAuthorNames(authors) : undefined;

  const image = isOriginalContent ? authorImage : brandImage || sourceLogo;

  return {
    authors: authorNames,
    image,
    isCreatorContent,
    source: provider.displayName,
  };
};
