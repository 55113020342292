import { type Badge } from "./badge";

export const categories = [
  "BUSINESS",
  "CELEBRITY",
  "FINANCE",
  "ENTERTAINMENT",
  "HEALTH",
  "LIFESTYLE",
  "NEWS",
  "POLITICS",
  "SCIENCE",
  "SPORTS",
  "STYLE",
  "TECHNOLOGY",
  "US",
  "WORLD",
] as const;

export const categoryBadgeType = "Category" as const;

export type CategoryBadge = (typeof categories)[number];

export const getCategoryBadge = ({
  categoryLabel,
}: {
  categoryLabel: string;
}): Badge[] | undefined => {
  const categoryLabelUppercase = categoryLabel
    .toUpperCase()
    .replaceAll(".", ""); // Handle U.S.
  const id = categories.find((category) => category === categoryLabelUppercase);

  if (id) {
    return [{ id, type: categoryBadgeType }];
  }
};
