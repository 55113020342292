"use client";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { type StreamItem } from "@/components/shared/Stream/data";
import { useNcpCacheContext } from "@/contexts/NcpCacheContext";
import {
  STREAM_LOADING_STATES,
  type NCPStreamData,
} from "@/contexts/NcpCacheContext/types";
import { beaconError } from "@/lib/beacon";
import { TimeoutError } from "@/lib/http";
import { useRequestContext } from "../request/client";
import { sanitizeStreamData, type StoryItem } from "../streamDataUtils";
import {
  FETCH_CREDENTIALS,
  type FetchStreamArgs,
  type FetchCredential,
  fetchStream,
  type NCPStreamViewParams,
  type NCPStream,
} from "./api";

export interface PaginatedNCPStream<ItemType> extends NCPStreamData<ItemType> {
  fetchNextPage: () => void;
  isLoading: boolean;
}

const defaultHeaders = {};

export interface StreamArguments<StreamItem> {
  credentials?: FetchCredential;
  headers?: {
    Cookie?: string;
  };
  initialStreamData?: NCPStream<StoryItem>;
  isItemType: (item: unknown) => item is StreamItem;
  nextPaginationCursor?: string;
  params: NCPStreamViewParams;
  streamKey: string;
  fetchImpl?: (
    args: FetchStreamArgs<StreamItem>,
  ) => Promise<NCPStream<StreamItem>>;
}

/**
 * Client-side hook for requesting additional pages of a stream from NCP
 */
export const useStream = <ItemType>({
  credentials = FETCH_CREDENTIALS.SAME_ORIGIN,
  headers = defaultHeaders,
  initialStreamData,
  isItemType,
  nextPaginationCursor = "",
  params,
  streamKey,
  fetchImpl = fetchStream,
}: StreamArguments<ItemType>): PaginatedNCPStream<StoryItem> => {
  const mountRef = useRef(false);
  const paginationRef = useRef(nextPaginationCursor);
  const { features } = useRequestContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { cacheStream, cachedStreams, setStreamError } = useNcpCacheContext();
  const cacheKey = useMemo(() => getCacheKeyFromParams(params), [params]);

  useEffect(() => {
    if (!initialStreamData || mountRef.current) return;

    if (!cachedStreams[cacheKey]) {
      cacheStream(
        {
          items: initialStreamData.stream,
          nextPage: initialStreamData.nextPage,
          nextPaginationCursor: initialStreamData.pagination.uuids,
        },
        cacheKey,
      );
    }
    mountRef.current = true;
  }, [cacheKey, cachedStreams, cacheStream, initialStreamData]);

  const stream = useMemo(() => {
    return (
      cachedStreams[cacheKey] || {
        currentPage: 0,
        items: [],
        loadingState: STREAM_LOADING_STATES.READY,
        nextPage: true,
        nextPaginationCursor,
      }
    );
  }, [cacheKey, cachedStreams, nextPaginationCursor]);

  const fetchNextPage = useCallback(async () => {
    setIsLoading(true);

    try {
      const promise = fetchImpl({
        credentials,
        headers,
        isItemType,
        paginationCursor: paginationRef.current || stream.nextPaginationCursor,
        params,
        streamKey,
      });

      // Race the stream fetch against a timeout that will beacon an error to
      // the server if the timeout expires OR the fetch fails. If the promise
      // resolves normally, no beacon is fired. Note that beacons are sampled
      // Ideally we would have a generic fetch with timeout but we don't want
      // to enforce a 2 second timeout, just beacon after 2 seconds. Later on
      // we will have Open Telemtry to track these metrics instead.
      const timeout = new Promise((_, reject) =>
        setTimeout(
          () => reject(new TimeoutError("stream fetch timed out after 2s")),
          2_000,
        ),
      );
      Promise.race([promise, timeout]).catch((e) => {
        try {
          e.features = Object.keys(features).join(",");
          e.streamKey = streamKey;
          beaconError("fetch_stream", e);
        } catch (_) {
          // do nothing
        }
      });

      const results = await promise;
      const sanitizedStreamItems = sanitizeStreamData(
        results.stream as StreamItem[],
      );
      cacheStream(
        {
          items: sanitizedStreamItems,
          nextPage: results.nextPage,
          nextPaginationCursor: results.pagination?.uuids,
        },
        cacheKey,
      );
      paginationRef.current = results.pagination?.uuids;
      setIsLoading(false);
    } catch (error) {
      setStreamError({ error }, cacheKey);
    }
  }, [
    cacheKey,
    cacheStream,
    credentials,
    features,
    fetchImpl,
    headers,
    isItemType,
    params,
    setStreamError,
    streamKey,
    stream.nextPaginationCursor,
  ]);

  return {
    currentPage: stream.currentPage,
    error: stream.error,
    fetchNextPage,
    isLoading,
    items: stream.items,
    loadingState: stream.loadingState,
    nextPaginationCursor: paginationRef.current,
  };
};

function getCacheKeyFromParams(obj: any) {
  return JSON.stringify(
    Object.keys(obj)
      .sort()
      .map((k) => obj[k]),
  );
}
