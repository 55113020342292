const cors =
  typeof window !== "undefined" &&
  window.location.hostname.endsWith(".yahoo.com") &&
  window.location.protocol === "https:";

const NCP_CLIENT_ORIGIN = cors ? process.env.NEXT_PUBLIC_NCP_ORIGIN : "";

export const NCP_ORIGIN = process.env.NCP_ORIGIN || NCP_CLIENT_ORIGIN;

export enum ImageTags {
  MD = "448x252|2|95",
  XS = "74x74|2|95",
}
