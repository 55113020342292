import { type Badge } from "./badge";

export const liveBadge = ["LIVE"] as const;

export const liveBadgeType = "Live" as const;

export type LiveBadge = (typeof liveBadge)[number];

export const getLiveBadge = ({
  isLiveBlog,
}: {
  isLiveBlog: boolean;
}): Badge | undefined => {
  if (!isLiveBlog) return;

  return { id: "LIVE", type: liveBadgeType };
};

export const getLiveBadges = ({
  isLiveBlog,
}: {
  isLiveBlog: boolean;
}): Badge[] | undefined => {
  const liveBadge = getLiveBadge({ isLiveBlog });

  if (!liveBadge) return;

  return [liveBadge].flatMap((x) => x || []);
};

export const getlLiveBadges = ({
  isLiveBlog,
}: {
  isLiveBlog: boolean;
}): Badge[] | undefined => {
  const liveBadge = getLiveBadges({
    isLiveBlog,
  });
  if (liveBadge) return liveBadge;
};
